import * as React from "react"
import { Link } from 'gatsby'

import * as styles from '../styles/footer.module.css'

const Footer = () => (
	<footer className={styles.footer}>
		<Link to="/">
			<img className={styles.logo} src="/svg/tellerm-serif.svg" />
		</Link>
		<span className={styles.copyright}>© {new Date().getFullYear()}</span>
		<span className={styles.seperator}>|</span>
		<Link to="//twitter.com/tellerm_">
			<img className={styles.twitter} src="/images/logo-twitter.png" />
		</Link>
		<Link to="/contact">
			<img className={styles.contact} src="/svg/icons/telephone.svg" />
		</Link>
	</footer>
)

export default Footer
