import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div style={{textAlign: 'center'}}>
      <h1 style={{margin: '0'}}>404: Looks like you're lost</h1>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" description="You might be lost"/>

export default NotFoundPage
