import * as React from "react"
// import PropTypes from 'prop-types'
import { Link } from "gatsby"
import { SlChemistry } from "react-icons/sl"
import { PiFlaskDuotone } from "react-icons/pi"

import * as styles from "../styles/menu.module.css"

const links = [
  // {
  //   text: "polynomic",
  //   url: "/polynomic/",
  //   description: "Polynomic NFT",
  // },
  {
    text: "math",
    url: "/math/",
    description: "Math"
  },
  {
    text: "blog",
    url: "/blog/",
    description: "Rambling",
  },
  {
    // text: <SlChemistry />,
    text: <PiFlaskDuotone />,
    url: "#lab",
    description: "Lab",
    className: styles.lab,
    disabled: true
  },
]

const Menu = () => (
  <div className={styles.menu}>
    {links.map((link, i) => (
      <React.Fragment key={link.url}>
        <div className="li">
          {!link.disabled && <Link 
            to={link.url} 
            alt={link.description} 
            className={[styles.link, link.className || '', 'font-sans'].join(' ')}
            children={link.text}
          />}
          {link.disabled && <span
            className={[styles.link, link.className || '', 'font-sans'].join(' ')}
            children={link.text}
          />}
        </div>
      </React.Fragment>
    ))}
  </div>
)

export default Menu
