import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Menu from "./menu"

import * as styles from "../styles/header.module.css"

const Header = ({ siteTitle }) => (
  <header className={`${styles.header} container`} >
    <Link to="/" className={styles.logoLink}>
      <img className={styles.logo} src="/svg/tellerm-serif.svg" />
    </Link>
    <Menu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
